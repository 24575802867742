<template>
  <div>
    <slot name="ProgressBar"/>
    <page-header
      :title="`원하는 진행 스타일을\n선택해주세요.`"
      :titleSmall="`(복수 선택)`"
    >
    </page-header>

    <div class="ui-border-line ui-h-0 ui-mb-1"></div>

    <tag-box>
      <li
        v-for="(row, key) in $config.constant.mcStyle"
        :key="key"
        @click="() => {
          let idx = mcStyle.indexOf(row)
          if (idx === -1) {
            mcStyle.push(row)
          } else {
            mcStyle.splice(idx, 1)
          }
        }"
        :class="{ on: mcStyle.indexOf(row) !== -1 }"
      >
        {{row}}
      </li>
    </tag-box>

    <div class="ui-border-line ui-h-0 ui-mb-6"></div>

    <footer-box
      submitText="다음"
      :submitCb="() => nextStep()"
      :submitDisabled="!validate"
    >
    </footer-box>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import TagBox from '@/components/common/TagBox'
import FooterBox from '@/components/common/FooterBox'

export default {
  components: {
    PageHeader,
    TagBox,
    FooterBox,
  },
  data() {
    const postOffer = this.$store.state.postOffer
    let mcStyle = []
    if (postOffer.mcStyle) mcStyle = postOffer.mcStyle.split('|')
    return {
      mcStyle,
    }
  },
  computed: {
    step: {
      get() {
        return this.$route.params.step
      },
    },
    postOffer: {
      get() {
        return this.$store.state.postOffer
      },
      set(val) {
        this.$store.commit('setPostOffer', val)
      },
    },
    validate: {
      get() {
        return this.mcStyle.length > 0
      },
    },
  },
  methods: {
    nextStep() {
      this.postOffer = { wmcStyle: this.mcStyle.join('|') }
      this.$parent.nextStep()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
