var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("ProgressBar"),
      _c("page-header", {
        attrs: {
          title: "원하는 진행 스타일을\n선택해주세요.",
          titleSmall: "(복수 선택)"
        }
      }),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-1" }),
      _c(
        "tag-box",
        _vm._l(_vm.$config.constant.mcStyle, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: { on: _vm.mcStyle.indexOf(row) !== -1 },
              on: {
                click: function() {
                  var idx = _vm.mcStyle.indexOf(row)
                  if (idx === -1) {
                    _vm.mcStyle.push(row)
                  } else {
                    _vm.mcStyle.splice(idx, 1)
                  }
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + " ")]
          )
        }),
        0
      ),
      _c("div", { staticClass: "ui-border-line ui-h-0 ui-mb-6" }),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }